<template>
  <div>
    <!-- Maquetar dentro de este div -->
  </div>
</template>
<script>


export default {
  name: "Servicios",
  components: {

  },
};
</script>
<style lang="scoped">
</style>