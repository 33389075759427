<template>
  <div class="bg-about">
 <div >
      <!-- Maquetar dentro de este div -->
      <section id="hero" class="about">
         <li v-show="computedMobile">
            <div class="logo mx-5 px-5 position-relative">
              <a href="/"><img src="assets/img/logo-white.png" alt="CNA Compañia de negocios agropecuarios CNA SA" class="img-fluid logo" /></a>
            </div>
          </li>
         <div class="container" data-aos="fade-in" data-aos-delay="200">
            <div class="row  title text-left">
               <div class="col-md-6 col-sm- p-5 about-box " >
                  <h1 class="initialism position-relative" style="margin:0 0 20px 0" >
                     Quiénes somos
                  </h1>
                 
                  <h2 class="text-left " >
                    "  Creemos en las buenas prácticas agrícolas y responsables en
                cada uno de los negocios pactados, asumiendo nuestro rol y
                comprometiéndonos al máximo para lograr la mayor eficiencia
                posible."</h2>
                
                 
               </div>
               <div class="col-6 col-md-4"></div>
            </div>
         </div>
         
      </section>
      <div class="container about-about py-5">
           <div class="section-title" data-aos="">
            <h2>Nosotros</h2>
            <p>
                     Somos una empresa Argentina con una reconocida trayectoria en el mercado, enfocada en brindar soluciones a una gran diversidad de cultivos.  <br> 
                     Nos dedicamos a la importación, distribución y venta de productos fertilizantes, destinados a las industrias agrícolas, químicas y petroleras. <br> 
                     Trabajamos en el desarrollo continuo de una red comercial que recorre las diferentes zonas productivas, lo que nos permite estar cerca de nuestros clientes y contribuir al éxito de sus negocios.  
                  </p>
           
          </div>
         <div class="row row-cols-1 row-cols-lg-3 g-2 g-lg-3">
            <div class="col">
               <div class="p-3  text-left"
                data-aos="zoom-in"
              data-aos-delay="100"
              >
  <div class="icon-box mt-4 mt-xl-0">
              <img
                  src="assets/img/mision.png"
                  class="img-fluid"
                  alt=""
                />            
              </div>
                                   <h2>Misión</h2>

                  <p>Satisfacer las necesidades básicas de alimentos y biocombustibles. Contribuir a la construcción de un ambiente más sustentable y que nuestros clientes nos elijan por los valores que nos conforman.</p>
               </div>
            </div>
            <div class="col">
                 <div class="p-3  text-left"
                data-aos="zoom-in"
              data-aos-delay="100"
              >
               <div class="icon-box mt-4 mt-xl-0">
              <img
                  src="assets/img/vision.png"
                  class="img-fluid"
                  alt=""
                />            
              </div>
                  <h2>Visión</h2>
                  <p>Ser reconocidos como la compañía Argentina que mejora la calidad de vida de los individuos a través de nuestros altos estándares de calidad en nuestros  productos y servicios.  </p>
               </div>
            </div>
            <div class="col">
                 <div class="p-3  text-left"
                data-aos="zoom-in"
              data-aos-delay="100"
              >
              <div class="icon-box mt-4 mt-xl-0">
              <img
                  src="assets/img/valor.png"
                  class="img-fluid "
                  alt=""
                />            
              </div>
                  <h2>Valores</h2>
                  <p>Nuestra base principal es el respeto por los seres humanos y el medio ambiente. Creamos relaciones sólidas desde la honestidad, rectitud y transparencia. Valoramos la confianza y creemos en la palabra como un sello de lealtad y compromiso. </p>
               </div>
            </div>
         </div>
      </div>
      
   </div>
  </div>
</template>
<script>

import GradientColor from "./../utils/GradientColor";

export default {
  name: "QuienesSomos",
  components: {
     
  },  
  data: () => ({
    mobile: false
    }),
  mounted () {
    this.mobile = screen.width <= 768 ? true : false;
  },
  computed: {
    computedMobile () {
      return this.mobile;
    },
    gradient() {
      return GradientColor.gradientColors;
    },
  },
};
</script>
<style scoped>
.logo {
  top: -50px;
}
.img-fluid {
    max-width: 50%;
    height: auto;
}
</style>